import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				El Sanatları Sanatı, Her Seferinde Tek Mürekkep
			</title>
			<meta name={"description"} content={"InkFusion Tattoo Studio'da, benzersiz tarzınıza ve tercihlerinize hitap eden çok çeşitli, yüksek kaliteli dövme hizmetleri sunmaktan gurur duyuyoruz."} />
			<meta property={"og:title"} content={"El Sanatları Sanatı, Her Seferinde Tek Mürekkep"} />
			<meta property={"og:description"} content={"InkFusion Tattoo Studio'da, benzersiz tarzınıza ve tercihlerinize hitap eden çok çeşitli, yüksek kaliteli dövme hizmetleri sunmaktan gurur duyuyoruz."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-4.jpg?v=2024-06-06T12:24:03.531Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header>
		<Section background="--color-dark" padding="80px 0 0 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="100%"
				>
					Vizyonunuzu Gerçeğe Dönüştürmeye Hazır mısınız?
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					InkFusion Tattoo Studio'yu ziyaret ederek yeni dövmenize doğru ilk adımı atın. Yetenekli sanatçılarımız sizinle çalışmaktan ve ömür boyu gururla taşıyacağınız bir sanat eseri yaratmaktan heyecan duyuyor. Randevunuzu bugün alın ve her seferinde bir mürekkep kullanarak sanat eserleri oluşturmanıza yardımcı olalım.
				</Text>
				<Link
					href="+0964519995"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					0964519995
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Saltivs'ke Hwy, 66, Kharkiv, Kharkiv Oblast, 61000
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/6661aa1f1d712a0023348f11/images/2-3.jpg?v=2024-06-06T12:24:03.520Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
					text-transform="uppercase"
				>
					Sunduğumuz tüm seçenekleri keşfetmek ve sizin için mükemmel dövmeyi keşfetmek için bugün bizimle iletişime geçin veya stüdyomuzu ziyaret edin.
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});